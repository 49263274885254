import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {HashPicture} from './HashPicture';
import {KeysGenerator} from './KeysGenerator';

ReactDOM.render(
  <React.StrictMode>
    {window.location?.search.indexOf('mode=3') >= 0 ? 
      <HashPicture /> 
      : 
      window.location?.search.indexOf('mode=4') >= 0 ? 
      <KeysGenerator /> 
      : 
      <App />}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
