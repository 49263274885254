import React, {useEffect, useState} from 'react'
import CryptoJS from 'crypto-js'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import * as arrayBuffFrom from './ArrayBuff'


const latin = 'abcdefghijklmnopqrstuvwxyz'
const digits = '0123456789'
const greek = 'θςερτυιπασδφηξλζψωβμΘΣΔΞΛΨΩ'
const symbols = latin + latin.toUpperCase() + digits + greek

const createKey = (alphabet, lenMin, lenMax) => {
    const lenKey = lenMin + Math.floor((lenMax - lenMin + 1) * Math.random())
    const lenAlphabet = alphabet.length

    let key = ''
    for (let i = 0; i < lenKey; i++)
        key += alphabet[Math.floor(lenAlphabet * Math.random())]
    
    return key
}

export const KeysGenerator = () => {
    //-------------------------------------------------------------------------------------
    //useState variables
    //Some variables needs useRef reference for use in setInterval handler
    //-------------------------------------------------------------------------------------

    const [alphabet, setAlphabet] = useState(symbols)
    const [lenMin, setLenMin] = useState(16)
    const [lenMax, setLenMax] = useState(16)
    const [src, setSrc] = useState()
    const [files, setFiles] = useState()
    const [index, setIndex] = useState(undefined)
    const [names, setNames] = useState([])
    const [keys, setKeys] = useState([])
    const [ciphers, setCiphers] = useState([])

    const fmtNum = value => ('0000000000' + value).slice(-3)
    const fmtKey = value => (value + '          ').slice(0, lenMax)
    const correctMinMax = (isMax) => {
        let min = Number(lenMin) || 1
        let max = Number(lenMax) || 1
        if (isMax && min > max) min = max
        if (!isMax && max < min) max = min
        setLenMin(min)
        setLenMax(max)
    }

    const calcFile = file => {
        const callback = arrayImage => {
            const name = file?.name
            const key = createKey(alphabet, lenMin, lenMax)
            const arrayKey = (new TextEncoder()).encode(key)
            const arrayKeyImage = arrayBuffFrom.concat(arrayKey, arrayImage)
            const wordArray = CryptoJS.lib.WordArray.create(arrayKeyImage)
            const cipher = CryptoJS.MD5(wordArray).toString()

            setNames(prev => ([...prev, name]))
            setKeys(prev => ([...prev, key]))
            setCiphers(prev => ([...prev, cipher]))
            setIndex(prev => prev === files.length-1 ? undefined : prev + 1)
            console.log(`${fmtNum(index+1)} ${cipher} ${key} ${name}`)
        }

        arrayBuffFrom.file(file, callback, (err) => alert(err))
        setSrc(URL.createObjectURL(file))
    }
    useEffect(() => {
        if (index === undefined) return

        calcFile(files[index])
    // eslint-disable-next-line
    }, [index])
    //-------------------------------------------------------------------------------------
    // Return now
    //-------------------------------------------------------------------------------------
    return <div id='main-keygen'>
        {/* -------------------------------------------------------------------------------------
        //                      Header
        //------------------------------------------------------------------------------------- */}
        <div className='position-relative'>
            <div className='header less'>{'Генерация КЛЮЧЕЙ и ШИФРОВ\nк выбранным ФАЙЛАМ'}</div>
        </div>

        {/* -------------------------------------------------------------------------------------
        // Алфавит:
        //------------------------------------------------------------------------------------- */}
        <div className='pb-1'>
            <div className='d-flex justify-content-start position-relative font-weight-bold'>Алфавит:</div>
            
            <textarea className='form-control fc1 bg-success t-yellow'
                placeholder='Введите алфавит'
                id='alphabet'
                value={alphabet}
                disabled={false}
                onChange={(e) => {
                    setAlphabet(e.target.value)
                }}
            />
        </div>
        {/* -------------------------------------------------------------------------------------
        // Длина ключа:
        //------------------------------------------------------------------------------------- */}
        <div className='d-flex pb-1 align-items-center font-weight-bold'>
            <span className='font-weight-bold'>Длина ключа:&nbsp;</span>
            
            <input className='form-control bg-success t-yellow inp'
                value={lenMin}
                onChange={e => setLenMin(e.target.value)}
                onBlur={() => correctMinMax(0)}
            />
            <span>&nbsp;-&nbsp;</span>
            <input className='form-control bg-success t-yellow inp'
                value={lenMax}
                onChange={e => setLenMax(e.target.value)}
                onBlur={() => correctMinMax(1)}
            />
        </div>
        {/* -------------------------------------------------------------------------------------
        // Картинка:
        //------------------------------------------------------------------------------------- */}
        <div className='pb-1 position-relative'>
            <div className={'position-absolute'}>
                <div className='d-flex flex-column line'>
                    <dev>{src && names[names.length-1]}</dev>
                    <dev>{src && ciphers[ciphers.length-1]}</dev>
                    <dev>{src && keys[keys.length-1]}</dev>
                </div>
            </div>

            <div className={'d-flex justify-content-end mt-1'}>
                {/* <div className='text-white'>A</div> */}
                {src && <img className={`picture`} src={src} alt=''/>}
                {/* <div className='text-white'>A</div> */}
            </div>

            <label htmlFor='image_uploads' className={`form-control fc t-yellow text-center mb-0 mt-1${src === undefined? ' bg-success height-big' : ' bg-blue'}`}>
                {'Выбрать файлы'}</label>

            <input type='file'
                className='input-file'
                id={'image_uploads'}
                accept={'.png'}   //accept={'.png, .jpg'}
                multiple
                onChange={(event) => {
                    setFiles(event.target.files)
                    setKeys([])
                    setCiphers([])
                    setNames([])
                    if (event.target.files.length) setIndex(0)
                }}
            />
        </div>
        {/* -------------------------------------------------------------------------------------
        // Результат:
        //------------------------------------------------------------------------------------- */}
        <div className='result'>
            {names.length === 0 && <>
                <p> Выберите файлы и получите для каждого файла ключ и шифр. </p>
                <p> Внимание! Результаты не повторяются. При каждом выборе файла (-ов) осуществляется генерация новых ключей. </p>
                <p> Результаты выводятся в данное икно и в консоль браузера, которая доступна по нажатию F12, вкладка "Консоль" </p>
            </>}
            {/* <div className='d-flex justify-content-start position-relative font-weight-bold'>Результат:</div> */}
            {/* <div className='font-weight-bold'>Результат:</div> */}
            {names.map((e, i) => 
                <div className='line'>
                    {`${fmtNum(i+1)}   ${ciphers[i]}   ${fmtKey(keys[i])}   ${names[i]}`}
                </div>
            )}
        </div>
    </div>
}
