import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
const QRCodeImpl = require('qr.js/lib/QRCode')
const ErrorCorrectLevel = require('qr.js/lib/ErrorCorrectLevel')

const QRCodeDiff = (props) => {
    const {
        value1,
        value2,
        level,
        bgColor,
        fgColor,
        ...otherProps
    } = props;

    function convertStr(str) {
        let out = '';
        for (let i = 0; i < str.length; i++) {
            let charcode = str.charCodeAt(i);
            if (charcode < 0x0080) {
                out += String.fromCharCode(charcode);
            } else if (charcode < 0x0800) {
                out += String.fromCharCode(0xc0 | (charcode >> 6));
                out += String.fromCharCode(0x80 | (charcode & 0x3f));
            } else if (charcode < 0xd800 || charcode >= 0xe000) {
                out += String.fromCharCode(0xe0 | (charcode >> 12));
                out += String.fromCharCode(0x80 | ((charcode >> 6) & 0x3f));
                out += String.fromCharCode(0x80 | (charcode & 0x3f));
            } else {
                // This is a surrogate pair, so we'll reconsitute the pieces and work
                // from that
                i++;
                charcode =
                    0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
                out += String.fromCharCode(0xf0 | (charcode >> 18));
                out += String.fromCharCode(0x80 | ((charcode >> 12) & 0x3f));
                out += String.fromCharCode(0x80 | ((charcode >> 6) & 0x3f));
                out += String.fromCharCode(0x80 | (charcode & 0x3f));
            }
        }
        return out;
    }

    function generatePath(cells) {
        const ops = []
        cells.forEach(function(row, y) {
            let start = null
            row.forEach(function(cell, x) {
                if (!cell && start !== null) {
                    ops.push(`M${start} ${y}h${x - start}v1H${start}z`)
                    start = null
                    return
                }

                if (x === row.length - 1) {
                    if (!cell) return
                    if (start === null) ops.push(`M${x},${y} h1v1H${x}z`)
                    else                ops.push(`M${start},${y} h${x + 1 - start}v1H${start}z`)
                    return
                }

                if (cell && start === null)
                    start = x;
            });
        });
        return ops.join('');
    }

    const generateDiff = (cells1, cells2) => {
        const size = Math.max (cells1.length, cells2.length)
        const k1 = cells1.length/size
        const k2 = cells2.length/size

        let cells = []
        for (let j = 0; j < size; ++j) {
            const j1 = Math.floor(j*k1)
            const j2 = Math.floor(j*k2)
            let row = []
            for (let i = 0; i < size; ++i) {
                const i1 = Math.floor(i*k1)
                const i2 = Math.floor(i*k2)
                row.push ((
                    // j1 in cells1     && j2 in cells2     &&
                    // i1 in cells1[j1] && i2 in cells2[j2] &&
                    cells1[j1][i1] === cells2[j2][i2]) ?
                    true : false)
            }
            cells.push (row)
        }
        return cells
    }

    const qrcode1 = new QRCodeImpl(-1, ErrorCorrectLevel[level]); qrcode1.addData(convertStr(value1)); qrcode1.make()
    const qrcode2 = new QRCodeImpl(-1, ErrorCorrectLevel[level]); qrcode2.addData(convertStr(value2)); qrcode2.make()

    const cells = generateDiff (qrcode1.modules, qrcode2.modules)
    if (cells === null) return null

    const numCells = cells.length
    const fgPath = generatePath(cells);

    return (
        <svg
            viewBox={`0 0 ${numCells} ${numCells}`}
            {...otherProps}>
            <path fill={bgColor} d={`M0,0 h${numCells}v${numCells}H0z`} />
            <path fill={fgColor} d={fgPath} />
        </svg>
    );
}

export default QRCodeDiff
