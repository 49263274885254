import CryptoJS from 'crypto-js'

export const file = (file, callbackSuccess, callbackError) => {
    const reader = new FileReader()

    reader.onload =  () => {
        callbackSuccess(new Uint8Array(reader.result))
    }
    reader.onerror =  () => callbackError()
    reader.readAsArrayBuffer(file);
}

export const uri = async (url, callbackSrc, callbackSuccess, callbackError) => {
    const response = await fetch(url).catch(() => {})
    if (!response) return 

    const blob = await response.blob()  //NB! may be not work in Safary
    const arrayBuff = await blob.arrayBuffer() 
    const src = URL.createObjectURL(blob)
    callbackSrc(src)
    callbackSuccess(new Uint8Array(arrayBuff))
}

export const concat = (arr1, arr2) => {
    const arrayBuff = new Uint8Array(arr1.length + arr2.length)

    arrayBuff.set(arr1, 0)
    arrayBuff.set(arr2, arr1.length)

    return arrayBuff
}

export const keyImage2Hash = async (key, url) => {
    if (!url) return undefined

    const response = await fetch(url).catch(() => undefined)
    if (!response) return undefined

    const blob = await response.blob()
    const arrayBuff = await blob.arrayBuffer() 
    const arrayImage = new Uint8Array(arrayBuff)

    const arrayKey = (new TextEncoder()).encode(key)
    
    // const arrayKeyImage = concat(arrayKey, arrayImage)
    const arrayKeyImage = new Uint8Array(arrayKey.length + arrayImage.length)
    arrayKeyImage.set(arrayKey, 0)
    arrayKeyImage.set(arrayImage, arrayKey.length)

    const wordArray = CryptoJS.lib.WordArray.create(arrayKeyImage)
    
    return CryptoJS.MD5(wordArray).toString()
}
