import React, {useEffect, useRef, useState, useMemo} from 'react'
import QRCode from "qrcode.react"
import CryptoJS from 'crypto-js'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import QRCodeDiff from "./QRCodeDiff";
import * as queryString from "query-string";
import * as arrayBuffFrom from './ArrayBuff'
// import {keyImage2Hash} from './ArrayBuff'


export const HashPicture = () => {
    //-------------------------------------------------------------------------------------
    //Text in languages
    //-------------------------------------------------------------------------------------
    const text = {
        en:
            {
                // Info: 'You can change the Key, Iage or GivenCipher\nand observe compliance changes.',
                // Header: 'Checking Compliance of\nSecretCode to a given Cipher',
                // OpenCode: 'GivenCipher',
                Info: 'You can change the Key, Image or GivenCipher and observe compliance changes.',
                Header: 'Checking Compliance of\nKey-Image-Cipher',
                OpenCode: 'GivenCipher',
    
                SecretCode: 'KeyImage',
                SecrCode: 'KeyImage',
                Hash: 'Hash',
                Difference: 'Difference',
                Key: 'Key',
                Image: 'Image',
                EnterKey: 'Enter Key',
                EnterCipher: 'Enter Cipher',
                LoadImage: 'Load IMAGE',
                LoadAnotherImage: 'CHECK THE ANOTHER IMAGE',
            },
        ru: {
            // Info: 'Вы можете изменять Ключ, Изображение или ВашШифр\nи наблюдать изменения соответствия.',
            // Header: 'Проверка соответствия Ключа к Изображению и Вашего шифра', 
            // OpenCode: 'ВашШифр',
            Info: 'Вы можете изменять Ключ, Изображение или ЗаданныйШифр и наблюдать изменения соответствия.',
            Header: 'Проверка соответствия\nКлюч-Изображение-Шифр\nЗаданному Шифру', 
            OpenCode: 'ЗаданныйШифр',

            SecretCode: 'КлючИзображение',
            SecrCode: 'КлючИзобр',
            Hash: 'Шифр',
            Difference: 'Разность',
            Key: 'Ключ',
            Image: 'Изображение',
            EnterKey: 'Введите Ключ',
            EnterCipher: 'Введите Ваш Шифр',
            LoadImage: 'Загрузить изображение',
            LoadAnotherImage: 'ПРОВЕРИТЬ ДРУГОЕ ИЗОБРАЖЕНИЕ',
        }
    }

    //Определяем язык (из адресной строки ...index.xx.html... выделяем xx)
    const location = window.location.href
    let lang = (/index\.(\w\w)\.html/).exec(location)
    if (lang) lang = lang[1]
    if (!(lang in text)) lang = 'en'
    // lang = 'ru' //NB!!!
    const {
        Header,
        SecretCode,
        SecrCode,
        Hash,
        OpenCode,
        Difference,
        Info,
        Key,
        Image,
        EnterKey,
        EnterCipher,
        LoadImage,
        LoadAnotherImage,
} = {...text[lang]}

    //-------------------------------------------------------------------------------------
    //useState variables
    //Some variables needs useRef reference for use in setInterval handler
    //-------------------------------------------------------------------------------------

    //Initialize by testing data
    const [secretKey, setSecretKey] = useState('')
    const [currSecretKey, setCurrSecretKey] = useState('')
    const [publicCode, setPublicCode] = useState('')
    const [src, setSrc] = useState()
    const [, setUrl] = useState()
    const [arrayImage, setArrayImage] = useState(() => new Uint8Array(0))
    const [phase, setPhase] = useState(0)   //Animation phase
    const [timerId, setTimerId] = useState(undefined)   //setInterval handler
    // const [opacity, setOpacity] = useState(0)

    //next 6 lines: for use in Timeout
    const timerRef = useRef()   
    timerRef.current = timerId
    const refSecretKey = useRef(secretKey) 
    refSecretKey.current = secretKey
    const refPhase = useRef()   
    refPhase.current = phase

    // NB!
    // useEffect(() => {
    //     if (!opacity) setOpacity(100)
    // },[opacity])

    // const time0 = useRef(0)
    // if (opacity === 0) {
    //     time0.current = new Date().getDate()
    //     setOpacity(50)
    // }
    // else if (opacity < 100 &&
    //     (new Date().getDate() - time0.current) > 3000)
    //         setOpacity(100)
    
    //-------------------------------------------------------------------------------------
    // useEffect.
    //On start:
    //  - extract secretKey and publicCode from location
    //  - start timer for animation
    //On finish:
    //  - stop timer if it's running
    //-------------------------------------------------------------------------------------
    useEffect(() => {
        const str = queryString.parse(getLocation(window.location.href).search)
        const pub = str.public_code || str.open_code || str.cipher
        const sec = str.secret_code || str.key
        const src = str.src
        // const src = props.src || str.src || '/img/m_1_bee.png'

        if (pub) setPublicCode(pub)
        if (sec) setSecretKey(sec)
        if (src) {
            setUrl(src)
            arrayBuffFrom.uri(src, setSrc, setArrayImage, () => alert("No File"))
        }

        //Start interval timer for animation
        setTimerId(setInterval(onTimeout, 100))

        //Stop timer
        return () => {
            if (timerId) {
                clearInterval(timerId)
                setTimerId(undefined)
            }
        }
        // eslint-disable-next-line
    }, [])     //only one time: onMount and onUnmount

    //-------------------------------------------------------------------------------------
    // onCalc - calculate Hash(KeyImage)
    //-------------------------------------------------------------------------------------
    const onCalc = () => {
        // keyImage2Hash(currSecretKey, url).then(console.log)  //test

        const arrayKey = (new TextEncoder()).encode(currSecretKey)
        const arrayKeyImage = arrayBuffFrom.concat(arrayKey, arrayImage)
        const wordArray = CryptoJS.lib.WordArray.create(arrayKeyImage)

        return CryptoJS.MD5(wordArray).toString()
    }

    //-------------------------------------------------------------------------------------
    // onTimeout - set next phase for animation
    //-------------------------------------------------------------------------------------
    const onTimeout = () => {
        if (refPhase.current >= refSecretKey.current.length) {
            if (timerRef.current !== undefined) {
                clearInterval(timerRef.current)
                setTimerId(undefined)
            }
        } else {
            setPhase(refPhase.current + 1)
            setCurrSecretKey(refSecretKey.current.slice(0, refPhase.current + 1))
        }
    }

    //-------------------------------------------------------------------------------------
    // onTLink - back to hash site
    //-------------------------------------------------------------------------------------
    function getLocation(href) {
        const match = href.match(/^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
        return match && {
            href: href,
            protocol: match[1],
            host: match[2],
            hostname: match[3],
            port: match[4],
            pathname: match[5],
            search: match[6],
            hash: match[7]
        }
    }

    //-------------------------------------------------------------------------------------
    // onRefresh - start animation
    //-------------------------------------------------------------------------------------
    const onRefresh = () => {
        if (timerId) clearInterval(timerId)
        setTimerId(setInterval(onTimeout, 100))
        
        setPhase(0)
        setCurrSecretKey('')
    }

    //-------------------------------------------------------------------------------------
    // Other code
    //-------------------------------------------------------------------------------------
    const getDifference = (first, second) => {
        let result = ''
        for (let i = 0; i < Math.max(first.length, second.length); i++) {
            result += first.charAt(i) === second.charAt(i) ? '0' : '1'
        }
        return result
    }

    const getDifferColored = (first, second) => {
        let isEqualFragment = true
        let fragment = ''
        let key = 0
        let result = []

        const pushFragment = () => {
            if (fragment.length) {
                if (isEqualFragment) result.push(<span key={key++} className={'t-green'}>{fragment}</span>)
                else result.push(<span key={key++} className={'t-red'}>{fragment}</span>)
            }
        }
        for (let i = 0; i < first.length; i++) {
            if ((first[i] === second[i] && isEqualFragment) ||
                (first[i] !== second[i] && !isEqualFragment)) fragment += first[i]
            else {
                pushFragment()
                isEqualFragment = !isEqualFragment
                fragment = first[i]
            }
        }
        pushFragment()

        return result
    }
    const hashKeyImage = useMemo(onCalc, [currSecretKey, arrayImage])
    const differPublic = getDifference(hashKeyImage, publicCode)
    const differPublicColored = getDifferColored(differPublic, '00000000000000000000000000000000')

    //-------------------------------------------------------------------------------------
    // Return now
    //-------------------------------------------------------------------------------------
    return <div id='main'>
        {/* -------------------------------------------------------------------------------------
        //                      Header
        //------------------------------------------------------------------------------------- */}
        <div className='position-relative border_bottom'>
            <div className='header less'>{Header}</div>
        </div>

        {/* -------------------------------------------------------------------------------------
        // Ключ:           <> 
        //------------------------------------------------------------------------------------- */}
        <div className='pb-1 border_bottom'>
            <div className='d-flex justify-content-start position-relative font-weight-bold'>
                {Key}:
                <img src={'/img/repeat.png'} className='position-absolute right-0 h-100' alt='' onClick={onRefresh}/>
            </div>
            
            {/* Введите ключ */}
            <input className='form-control fc bg-success t-yellow'
                placeholder={EnterKey}
                id='secret_code'
                value={currSecretKey}
                disabled={timerId !== undefined}
                onChange={(e) => {
                    setCurrSecretKey(e.target.value)
                    setSecretKey(e.target.value)
                }}
            />
        </div>
        {/* -------------------------------------------------------------------------------------
        // Картинка:           <>
        //------------------------------------------------------------------------------------- */}
        <div className='pb-1 border_bottom position-relative'>
            <div className={'position-absolute font-weight-bold'}>{Image}:
            </div>

            {/*NB! {src !== undefined && <div className={'position-absolute right-0 text-white'}>:
            <img src={'/img/repeat.png'} className='position-absolute right-0 h-100' alt='' onClick={() => {setOpacity(0)}}/>
            </div>} */}
            
            <div className={'d-flex justify-content-between mt-1'}>
                <div className='text-white'>A</div>
                {/* {src && <img className={`picture ${opacity ? 'opacity100' : 'opacity0'}`} src={src} alt=''/>} */}
                {src && <img className={`picture`} src={src} alt=''/>}
                <div className='text-white'>A</div>
            </div>

            <label htmlFor='image_uploads' className={`form-control fc t-yellow text-center mb-0 mt-1${src === undefined? ' bg-success height-big' : ' bg-blue'}`}>
                {src === undefined? LoadImage : LoadAnotherImage}</label>

            <input type='file'
                className='input-file'
                id={'image_uploads'}
                accept={'.png'}   //accept={'.png, .jpg'}
                onChange={(event) => {
                    const file = event.target.files[0]
                    if (!file) return 

                    setUrl(file.name)
                    setSrc(URL.createObjectURL(file))
                    arrayBuffFrom.file(file, setArrayImage, (err) => alert(err))
                    //NB! const name = file.name.slice(-37, -4)
                    // if(name.charAt(0) === '_')
                    //     setPublicCode(name.slice(1))
                    const start = file.name.lastIndexOf('_')
                    const end = file.name.lastIndexOf('.')
                    const params = start >=0 && start < end ? file.name.slice(start+1, end) : ''
                    const length = params.length
                    if (length >= (32+10)) {
                        const key = params.slice(32)
                        setCurrSecretKey(key)
                        setSecretKey(key)
                    }
                    if (length >= (32+10) || length === 32)
                        setPublicCode(params.slice(0, 32))

                    // setOpacity(0)
                }}
            />
        </div>
        {/* -------------------------------------------------------------------------------------
        //                      Hash(SecretCode):
        //------------------------------------------------------------------------------------- */}
        <div className='d-flex justify-content-start position-relative font-weight-bold'>
            {Hash}({SecretCode}):
            {/* <img src={'/img/repeat.png'} className='position-absolute right-0 h-100' alt='' onClick={onCalc}/> */}
        </div>
        <input
            className='field-check form-control fc'
            value={hashKeyImage}
            readOnly
        />

        <div className='clRowQR pb-1 border_bottom'>
            <QRCode className='clQR clQRshift' renderAs='svg' level='Q' value={hashKeyImage}/>
        </div>

        {/* -------------------------------------------------------------------------------------
        //                      OpenCode:
        //------------------------------------------------------------------------------------- */}

        <div className='d-flex justify-content-start position-relative font-weight-bold'>
            {OpenCode}:
        </div>
        
        {/* Введите ключ */}
        <input className='form-control fc bg-success t-yellow'
            id='public_code'
            placeholder={EnterCipher}
            value={publicCode}
            onChange={(e) => {
                setPublicCode(e.target.value)
            }}
        />

        <div className='clRowQR pb-1 border_bottom'>
            <QRCode className='clQR clQRshift' renderAs='svg' level='Q' value={publicCode}/>
        </div>

        {/* -------------------------------------------------------------------------------------
        //                      Hash(SecretCode) - OpenCode:
        //------------------------------------------------------------------------------------- */}
        <div className='d-flex justify-content-start position-relative font-weight-bold'>
            {Hash}({SecretCode}) - {OpenCode}:
        </div>
        <div className={`field-check form-control fc ${publicCode === hashKeyImage ? 'alert-success' : 'alert-warning'}`}>
            {differPublicColored}
        </div>

        <div className='clRowQR'>
            <QRCode className='clQR' renderAs='svg' level='Q' value={hashKeyImage}/>
            <button className='clQRsign clQRminus'>-</button>

            <QRCode className='clQR' renderAs='svg' level='Q' value={publicCode}/>
            <button className='clQRsign clQRequel'>=</button>

            <QRCodeDiff className='clQR' level='Q' value1={hashKeyImage} value2={publicCode} bgColor='red' fgColor={'green'}/>
        </div>

        {/* -------------------------------------------------------------------------------------
        //  Labels:            Hash            OpenCode            Difference
        //                  (SecretCode)
        //------------------------------------------------------------------------------------- */}
        <div>
            <label className='clLabelHash'>{Hash}({SecrCode})</label>
            <label className='clLabelOpen'>{OpenCode}</label>
            <label className='clLabelDiff'>{Difference}</label>
        </div>

        {/* -----------------------------------------------------------------------------------
        //                      Info Line
        //------------------------------------------------------------------------------------- */}

        <div className={`clInfo`} role="alert">
            {Info}
        </div>
    </div>
}
